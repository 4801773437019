export const seoTransform = (data) => {
  if (data == null || data.length === 0) {
    return "";
  } else {
    let value = data;
    value.trim();
    value = value.replace(/[^a-zA-Z0-9-\s]/g, "");
    value = value.replaceAll(" ", "-");
    function fun() {
      if (value.search("--") != -1) {
        const i = value.search("--");
        value = value.slice(0, i) + value.slice(i + 1);
        fun();
      }
    }
    fun();
    if (value[value.length - 1] === "-") {
      value = value.slice(0, value.length - 1);
    }
    value = value.toLowerCase();
    return value;
  }
};

export const urlTransform = (data) => {
  if (data == null || data.length === 0) {
    return "";
  } else {
    let value = data;
    value.trim();
    value = value.replace(/[^a-zA-Z0-9-\s/]/g, "");
    value = value.replaceAll(" ", "-");
    function fun() {
      if (value.search("--") != -1) {
        const i = value.search("--");
        value = value.slice(0, i) + value.slice(i + 1);
        fun();
      }
    }
    fun();
    if (value[value.length - 1] === "-") {
      value = value.slice(0, value.length - 1);
    }
    value = value.toLowerCase();
    return value;
  }
};
